import Layout from "../components/layout"
import SEO from "../components/seo"
import * as React from "react"
import { graphql } from "gatsby"
import TourCard, { ITourCardFragment } from "../components/tour-card"
import { ColumnIsOneFifth, Columns, ContainerOverview } from "../components/styled-components"

interface Props {
  data: {
    category: {
      name: string;
    };
    trips: {
      edges: {
        node: ITourCardFragment
      }[]
    };
  }
}

export default ({ data }: Props) => {
  return (
    <Layout>
      <SEO title={data.category.name}/>
      <ContainerOverview>
        <h2>{data.category.name}</h2>
        <Columns>
          {data.trips.edges.map((edge: any) => (
            <ColumnIsOneFifth key={edge.node.title}>
              <TourCard tour={edge.node}/>
            </ColumnIsOneFifth>
          ))}
        </Columns>
      </ContainerOverview>
    </Layout>
  )
}

export const query = graphql`
  query($categorySlug: String!, $name: String!) {
    category:contentfulCategory(fields: { categorySlug: { eq: $categorySlug } }) {
      name
    }
    trips:allContentfulTrip(
        limit: 25, 
        sort:{fields:internal___contentDigest}, 
        filter: {categories:{elemMatch:{name:{eq: $name}}}}
      ) {
      edges {
        node {
          ...TourCardFragment
        }
      }
    }
  }
`
